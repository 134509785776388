/** @jsx jsx */
import { jsx, Box, Heading, Text } from 'theme-ui';
// import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoIosCloseCircle } from 'react-icons/io';
import tick from 'assets/images/icons/tick.png';

const PriceTable = ({ price, isMonthly }) => {
  return (
    <Box
      sx={styles.priceTable}
      className={`priceCard ${price.isRecommended ? 'recommended' : ''}`}
    >
      {price?.isRecommended && (
        <Text as="span" sx={styles.recommended}>
          Recommended
        </Text>
      )}
      <div className="features-btn-wrapper">
        <div>
          <Box sx={styles.header}>
            <Box className="priceHeader">
              <Heading as="h3" sx={styles.title}>
                {price.title}
              </Heading>
            </Box>
            {price?.amount !== 0 && (
              <Box className="priceAmount">
                {/* <Text as="p" sx={styles.priceLabel}>
              Starting from
            </Text> */}

                {price?.amountOrg ? (
                  <Text as="p" sx={styles.priceStriked}>
                    ${price?.amountOrg?.toFixed(0)}
                    /mo
                  </Text>
                ) : (
                  ''
                )}

                <Text as="p" sx={styles.priceAmount}>
                  ${price?.amount?.toFixed(0)}
                  /mo
                </Text>
              </Box>
            )}
          </Box>
          <Text as="p" sx={styles.subtitle}>
            {price.subtitle}
          </Text>
          <Box as="ul" sx={styles.list}>
            {price?.features?.map((feat) => (
              <li
                key={feat.id}
                className={!feat.isAvailable ? 'unavailable' : ''}
              >
                {feat.isAvailable ? (
                  // <IoMdCheckmarkCircle color="#3FDBB1" size="30px" />
                  <img
                    style={{
                      width: `20px`,
                      height: `20px`,
                      marginRight: `9px`,
                    }}
                    src={tick}
                    alt="MoonAlgo Check Mark"
                  />
                ) : (
                  <IoIosCloseCircle color="#CED7E1" size="30px" />
                )}
                <span>{feat.title}</span>
              </li>
            ))}
          </Box>
          </div>
          <Box sx={{ textAlign: 'center' }} className="priceButton">
            <button
              data-cb-type={price.dataCbType}
              data-cb-plan-id={price.dataCbPlanId}
              className="btn btn-lg cta-1 mt-1-5"
            >
              {price.buttonText}
            </button>
          </Box>
        </div>
    </Box>
  );
};

export default PriceTable;

const styles = {
  priceTable: {
    width: ['300px', null, null, null, '350px', '400px'],
    border: `1px solid #e3e3e3`,
    borderRadius: 10,
    position: 'relative',
    padding: ['45px 20px 20px 20px', null, null, '55px 20px 30px 20px', null],
    '&.recommended': {
      borderColor: 'primary',
      borderWidth: '2.5px',
      color: 'text',
      // '@media only screen and (max-width: 767px)': {
      //   pt: 65,
      // },
      footer: {
        backgroundColor: '#F0F0F5',
        borderRadius: '0 0 10px 10px',
        '.price-label': {
          color: 'text',
        },
        '.price-value': {
          color: 'primary',
        },
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1.31,
    letterSpacing: '-0.55px',
  },
  subtitle: {
    mt: '6px',
  },
  priceLabel: {},
  priceAmount: {
    color: 'primary',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: 1.39,
    textAlign: 'right',
    letterSpacing: 'heading',
  },
  priceStriked: {
    color: '#505050',
    fontSize: '20px',
    fontWeight: '600',
    textDecoration: 'line-through red',
    marginTop: '-14px',
  },
  recommended: {
    backgroundColor: 'secondary',
    minHeight: '31px',
    alignItems: 'center',
    display: 'inline-flex',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0 8px',
    letterSpacing: '-0.14px',
    borderRadius: '3px',
    position: 'absolute',
    top: 10,
    left: [20, null, null, null, 40],
  },
  list: {
    listStyle: 'none',
    padding: 0,
    mt: [25, null, null, 30],
    maxWidth: `95%`,
    li: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: 14,
      lineHeight: 1.3,
      '+ li ': {
        mt: 14,
      },
      svg: {
        mr: '13px',
        mt: '5px',
      },
    },
    '.unavailable': {
      opacity: 0.5,
    },
  },
  button: {
    backgroundColor: '#FCF2E8',
    color: 'secondary',
    paddingLeft: 45,
    paddingRight: 45,
    marginTop: [35, null, null, 70],
    ':hover': {
      backgroundColor: 'secondary',
      color: '#fff',
    },
  },
};
