import './notification.scss';
import React, { useState, useEffect } from 'react';

const Notification = ({ data, onDismiss, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000);

    const nextTimer = setTimeout(() => {
      setIsVisible(true);
      onDismiss();
    }, 20000);

    return () => {
      clearTimeout(timer);
      clearTimeout(nextTimer);
    };
  }, [onDismiss]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div id="notification" className={isVisible ? 'visible' : ''}>
      <button
        id="close-icon"
        className="close-button"
        onClick={handleClose}
        aria-label="Close notification"
      ></button>

      <a href="/#pricing" className="notification-content">
        <img
          className="notification-img"
          src="/assets/images/moonalgo-og.png"
          alt="MoonAlgo Logo"
        />
        <span className="notification-text">
          {data.name} from {data.location} bought{' '}
          <span className="notification-product">{data.product}</span>
        </span>
      </a>
    </div>
  );
};

export default Notification;
