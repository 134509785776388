import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import NotificationContainer from '../components/notification/notification-container';
import Banner from '../sections/banner';
import UltimateFeatures from '../sections/ultimate-features';
import Features from '../sections/features';
// import IntroVideo from '../sections/intro-video';
// import UsefulFeatures from '../sections/useful-features';
// import Widgets from '../sections/widgets';
import Pricing from '../sections/pricing';
import Faq from '../sections/faq';
import Reviews from '../sections/reviews';

import './styles.scss';

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="" />
      <Banner />
      <UltimateFeatures />
      <Pricing />
      <Features />
      {/* <IntroVideo />
      <UsefulFeatures />
      <Widgets /> */}
      <Faq />
      <Reviews />
      <NotificationContainer />
    </Layout>
  );
}
