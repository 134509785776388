import React, { useState, useEffect } from 'react';
import Notification from './notification';
import notificationData from './notification.data';

const NotificationContainer = () => {
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(-1);
  const [isNotificationActive, setIsNotificationActive] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentNotificationIndex(0);
    }, 11000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (currentNotificationIndex >= 0) {
      const notificationTimer = setTimeout(() => {
        if (currentNotificationIndex < notificationData.length - 1) {
          setCurrentNotificationIndex(currentNotificationIndex + 1);
        }
      }, 20000);

      return () => clearTimeout(notificationTimer);
    }
  }, [currentNotificationIndex]);

  const currentNotificationData = notificationData[currentNotificationIndex];

  const handleNotificationClose = () => {
    setIsNotificationActive(false);
  };

  const handleDismiss = () => {
    setCurrentNotificationIndex(currentNotificationIndex + 1);
  };

  return (
    <>
      {isNotificationActive && currentNotificationIndex >= 0 && (
        <Notification
          data={currentNotificationData}
          onDismiss={handleDismiss}
          onClose={handleNotificationClose}
          
        />
      )}
    </>
  );
};

export default NotificationContainer;
