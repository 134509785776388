/** @jsx jsx */
import { useState } from 'react';
import { keyframes } from '@emotion/core';
import { jsx, Box, Container, Flex, Text, Button } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import PriceTable from 'components/cards/price-table';
import { rgba } from 'polished';


const silverPlan = {
    id: 1,
    title: 'Silver',
    subtitle: 'Access to MoonAlgo\'s algorithms.',
    isRecommended: false,
    buttonText: 'Subscribe Now',
    dataCbType: 'checkout',
    features: [
      {
        id: 1,
        isAvailable: true,
        title: 'Gain access to the top two MoonAlgo trading algorithms',
      },
      {
        id: 2,
        isAvailable: true,
        title: `Suitable for Crypto, Stocks, and Forex trading`,
      },
      {
        id: 3,
        isAvailable: true,
        title: `Receive real-time Buy & Sell Alerts and Signals`,
      },
      {
        id: 4,
        isAvailable: true,
        title: `Instantly access MoonAlgo Trading Algorithms via TradingView`,
      },
      {
        id: 5,
        isAvailable: true,
        title: `Connect with the Signals channel and VIP section on Discord`,
      },
      {
        id: 6,
        isAvailable: true,
        title: `Insider access on Discord to connect with Pros`,
      },
      {
        id: 7,
        isAvailable: true,
        title: `Free lifetime updates for continuous optimization`,
      },
      {
        id: 8,
        isAvailable: true,
        title: `Access Member Services support`,
      },
    ],
};

const goldPlan =   {
  id: 2,
  title: 'Gold',
  subtitle:
    'Access all the benefits of Silver + Fully automated trading system.',
  isRecommended: true,
  buttonText: 'Subscribe Now',
  dataCbType: 'checkout',
  features: [
    {
      id: 1,
      isAvailable: true,
      title: 'Access multiple MoonAlgo trading algorithms',
    },
    {
      id: 2,
      isAvailable: true,
      title: `Comprehensive support for full automation setup & integration`,
    },
    {
      id: 3,
      isAvailable: true,
      title: `Access Cloud Server APIs for fully automated trading`,
    },
    {
      id: 4,
      isAvailable: true,
      title: `Access algorithms' backtest results to make informed decisions`,
    },
    {
      id: 5,
      isAvailable: true,
      title: `Set up advanced alerts and automate orders for efficient trading`,
    },
    {
      id: 6,
      isAvailable: true,
      title: `Exclusive access to newly developed and proven algorithms`,
    },
    {
      id: 7,
      isAvailable: true,
      title: `Automated trading system works on over 100+ Trading Platforms`,
    },
    {
      id: 8,
      isAvailable: true,
      title: `VIP support service for a seamless trading experience and prompt assistance`,
    },
  ],
}

const addon1 = {
  id: 1,
  title: 'Automated Trading System',
  subtitle: 'Access to the Cloud API to automate trading execution.',
  isRecommended: false,
  buttonText: 'Subscribe Now',
  dataCbType: 'checkout',
  features: [
    {
      id: 1,
      isAvailable: true,
      title: `Comprehensive support for full automation setup & integration`,
    },
    {
      id: 2,
      isAvailable: true,
      title: `Access Cloud Server APIs for fully automated trading`,
    },
    {
      id: 3,
      isAvailable: true,
      title: `Set up advanced alerts and automate orders for efficient trading`,
    },
    {
      id: 4,
      isAvailable: true,
      title: `Automated trading system works on over 100+ Trading Platforms`,
    },
    {
      id: 5,
      isAvailable: true,
      title: `VIP support service for a seamless trading experience and prompt assistance`,
    },
  ],
};

const addon2 = {
  id: 2,
  title: 'Build or Automate Custom Strategy',
  subtitle: 'Tell us your strategy and we can code it and automate it for you.',
  isRecommended: false,
  buttonText: 'Subscribe Now',
  dataCbType: 'checkout',
  features: [
    {
      id: 1,
      isAvailable: true,
      title: 'Build and code a custom strategy for you',
    },
    {
      id: 2,
      isAvailable: true,
      title: `Automate your custom strategy`,
    },
    {
      id: 3,
      isAvailable: true,
      title: `Comprehensive support for full automation setup & integration`,
    },
    {
      id: 4,
      isAvailable: true,
      title: `VIP support service for a seamless trading experience and prompt assistance`,
    },
  ],
};

const monthlyPlan = [
  {
    id: silverPlan.id,
    title: silverPlan.title,
    subtitle: silverPlan.subtitle,
    amount: 49,
    isRecommended: silverPlan.isRecommended,
    buttonText: silverPlan.buttonText,
    dataCbType: silverPlan.dataCbType,
    dataCbPlanId: 'silver-membership',
    features: silverPlan.features,
  },
  {
    id: goldPlan.id,
    title: goldPlan.title,
    subtitle: goldPlan.subtitle,
    amount: 199,
    isRecommended: goldPlan.isRecommended,
    buttonText: goldPlan.buttonText,
    dataCbType: goldPlan.dataCbType,
    dataCbPlanId: 'gold-membership',
    features: goldPlan.features,
  },
];
const annualPlan = [
  {
    id: silverPlan.id,
    title: silverPlan.title,
    subtitle: silverPlan.subtitle,
    amountOrg: 49,
    amount: 39,
    isRecommended: silverPlan.isRecommended,
    buttonText: silverPlan.buttonText,
    dataCbType: silverPlan.dataCbType,
    dataCbPlanId: 'silver-membership-annual',
    features: silverPlan.features,
  },
  {
    id: goldPlan.id,
    title: goldPlan.title,
    subtitle: goldPlan.subtitle,
    amountOrg: 199,
    amount: 159,
    isRecommended: goldPlan.isRecommended,
    buttonText: goldPlan.buttonText,
    dataCbType: goldPlan.dataCbType,
    dataCbPlanId: 'gold-membership-annual',
    features: goldPlan.features,
  },
];

const monthlyAddon = [
  {
    id: addon1.id,
    title: addon1.title,
    subtitle: addon1.subtitle,
    amount: 49,
    isRecommended: addon1.isRecommended,
    buttonText: addon1.buttonText,
    dataCbType: addon1.dataCbType,
    dataCbPlanId: 'silver-membership',
    features: addon1.features,
  },
  {
    id: addon2.id,
    title: addon2.title,
    subtitle: addon2.subtitle,
    amount: 199,
    isRecommended: addon2.isRecommended,
    buttonText: addon2.buttonText,
    dataCbType: addon2.dataCbType,
    dataCbPlanId: 'gold-membership',
    features: addon2.features,
  },
];
const annualAddon = [
  {
    id: addon1.id,
    title: addon1.title,
    subtitle: addon1.subtitle,
    amountOrg: 49,
    amount: 39,
    isRecommended: addon1.isRecommended,
    buttonText: addon1.buttonText,
    dataCbType: addon1.dataCbType,
    dataCbPlanId: 'silver-membership-annual',
    features: addon1.features,
  },
  {
    id: addon2.id,
    title: addon2.title,
    subtitle: addon2.subtitle,
    amountOrg: 199,
    amount: 159,
    isRecommended: addon2.isRecommended,
    buttonText: addon2.buttonText,
    dataCbType: addon2.dataCbType,
    dataCbPlanId: 'gold-membership-annual',
    features: addon2.features,
  },
];




const Pricing = () => {
  const [plan, setPlan] = useState({
    active: 'monthlyPlan',
  });

  const handlePlan = (selectedPlan) => {
    setPlan({
      active: selectedPlan,
    });
  };

  return (
    <div className="cloud-bg">
      <Box
        as="section"
        id="pricing"
        sx={styles.section}
        variant="section.pricing"
        className="wh-tran"
      >
        <Container>
          <SectionHeading
            sx={styles.heading}
            title="Start Your Journey to the Moon 🚀"
            description=""
          />
          <Flex sx={styles.priceSwitcher}>
            <Text as="span" className="discount">
              Save 20%
            </Text>
            <Button
              variant="text"
              onClick={() => handlePlan('monthlyPlan')}
              className={`plan-toggle ${
                plan.active === 'monthlyPlan' ? 'active' : ''
              }`}
            >
              Monthly Plan
            </Button>
            <Button
              variant="text"
              onClick={() => handlePlan('annualPlan')}
              className={`plan-toggle ${
                plan.active === 'annualPlan' ? 'active' : ''
              }`}
            >
              Annual Plan
            </Button>
          </Flex>
          <div className="overflow-x-auto">
          <Flex sx={styles.priceWrapper}>
            {monthlyPlan.map((price, index) => (
              <div
                className={`plan-toggle ${
                  plan.active === 'monthlyPlan' ? '' : 'hidecard'
                }`}
                key={index}
              >
                <PriceTable
                  price={price}
                  key={index}
                  activePlan={plan.active}
                />
              </div>
            ))}

            {annualPlan.map((price, index) => (
              <div
                className={`plan-toggle ${
                  plan.active === 'annualPlan' ? '' : 'hidecard'
                }`}
                key={index}
              >
                <PriceTable
                  price={price}
                  key={index + monthlyPlan.length}
                  activePlan={plan.active}
                />
              </div>
            ))}
          </Flex>
          </div>
        </Container>

        {/* <Container>
          <SectionHeading
            className="mt-2"
            sx={styles.heading}
            title="ADD-ONS"
            description=""
          />
          <div className="overflow-x-auto">
          <Flex sx={styles.priceWrapper}>
            {monthlyAddon.map((price, index) => (
              <div
                className={`plan-toggle ${
                  plan.active === 'monthlyPlan' ? '' : 'hidecard'
                }`}
                key={index}
              >
                <PriceTable
                  price={price}
                  key={index}
                  activePlan={plan.active}
                />
              </div>
            ))}

            {annualAddon.map((price, index) => (
              <div
                className={`plan-toggle ${
                  plan.active === 'annualPlan' ? '' : 'hidecard'
                }`}
                key={index}
              >
                <PriceTable
                  price={price}
                  key={index + monthlyPlan.length}
                  activePlan={plan.active}
                />
              </div>
            ))}
          </Flex>
          </div>
        </Container> */}
      </Box>
    </div>
  );
};

export default Pricing;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeIn2 = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const styles = {
  heading: {
    mb: [20, null, null, null],
  },
  priceSwitcher: {
    backgroundColor: '#F7F8FB',
    borderRadius: '5px',
    border: `1px solid ${rgba('#fff', 0.2)}`,
    margin: ['0 auto 20px', null, null, '0 auto 25px'],
    maxWidth: 300,
    position: 'relative',
    p: 2,
    '.discount': {
      position: 'absolute',
      backgroundColor: 'primary',
      color: '#fff',
      minHeight: 25,
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      fontSize: 13,
      fontWeight: 700,
      textTransform: 'unset',
      borderRadius: 25,
      right: 38,
      top: '-17px',
    },
    button: {
      minHeight: '48px',
      px: '25px',
      fontWeight: 500,
      '&.active': {
        backgroundColor: '#fff',
        color: 'text',
      },
      ':focus': {
        outline: '0 none',
      },
    },
  },
  priceWrapper: {
    gap: 12,
    display: 'flex',
    width: 'max-content',
    marginRight: 'auto',
    marginLeft: 'auto',
    gridTemplateColumns: [
      'repeat(1, 340px)',
      'repeat(1, 340px)',
      'repeat(1, 340px)',
      'repeat(2,1fr)',
      'repeat(2, 430px)',
      'repeat(2, 440px)',
      'repeat(2, 480px)',
    ],
    justifyContent: 'center',
    '.priceCard': {
      '@media only screen and (min-width: 768px)': {
        height: `100%`,
      },
      background: `${rgba(255, 255, 255, 0.5)}`,
      '.priceHeader': {
        animation: `${fadeIn} 0.8s linear`,
        maxWidth: `70%`,
      },
      'ul > li': {
        animation: `${fadeIn2} 0.7s linear`,
      },
      '.priceAmount': {
        animation: `${fadeIn} 0.9s linear`,
      },
      '.priceButton': {
        animation: `${fadeIn2} 0.7s linear`,
      },
    },
  },
};
