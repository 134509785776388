/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
// import SectionHeading from 'components/section-heading';
import UltimateFeature from 'components/cards/ultimate-feature';

import bulb from 'assets/images/icons/bulb.png';
import dart from 'assets/images/icons/dart.png';
import rocket from 'assets/images/icons/rocket.png';
// import trophy from 'assets/images/icons/trophy.png';

const data = [
  {
    id: 1,
    icon: bulb,
    title: 'The Problem',
    description:
      'Many traders struggle with complicated charts that are loaded with lines, indicators, and oscillators, resulting in confusion and indecision when it comes to making trades.',
  },
  {
    id: 2,
    icon: dart,
    title: 'Best Strategy',
    description:
      'MoonAlgo provides powerful algorithm tools that connect to private data and plug into your charts, providing timely buy and sell signals for any market and any timeframe.',
  },
  {
    id: 3,
    icon: rocket,
    title: 'Never Miss Out',
    description:
      'Stay ahead of the game with real-time alerts from MoonAlgo - Never miss out on any stock, forex, or crypto opportunity with alerts delivered via Discord, SMS, or push notifications, in any timeframe.',
  },
  // {
  //   id: 4,
  //   icon: trophy,
  //   title: 'Award history',
  //   description: `Keep secure with fluent 2-factor authentication full activity.`,
  // },
];

const UltimateFeatures = () => {
  return (
    <Box as="section" id="features" variant="section.ultimateFeature">
      <div
        className="title-content"
        style={{ textAlign: 'center', marginBottom: '16px' }}
      >
        <h2 className="section-title hr-line">Keep it Simple</h2>
      </div>
      <Container>
        <Box sx={styles.features}>
          {data?.map((item) => (
            <UltimateFeature key={item.id} data={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default UltimateFeatures;

const styles = {
  heading: {
    marginBottom: [60, 60, 60, 80],
  },
  features: {
    gap: ['35px 60px', 60, 60, 40, 30, 60],
    display: ['grid', 'grid'],
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(3, 1fr)',
    ],
  },
};
