/** @jsx jsx */
import { jsx, Image } from 'theme-ui';

// import { jsx, Box, Container, Text, Image as Img } from 'theme-ui';
// import SectionHeading from 'components/section-heading';
// import Feature from 'components/cards/feature';
import smChart from 'assets/images/sm-chart.svg';
import tradingSig from 'assets/images/trading-sig.svg';
import buyBtn from 'assets/images/buy-btn.svg';

import { LearnMore } from 'components/link';

// import checkFilledCircle from 'assets/images/icons/check-circle-filled.png';
// import { Fragment } from 'react';

// const data = [
//   {
//     id: 1,
//     color: '#28D1DC',
//     value: '80K+',
//     title: 'We have more than customers',
//   },
//   {
//     id: 2,
//     color: '#FF753A',
//     value: '150+',
//     title: 'Free online tutorials videos available',
//   },
//   {
//     id: 3,
//     color: '#FA578E',
//     value: '90+',
//     title: 'Daily updated blog post maintain',
//   },
//   {
//     id: 4,
//     color: '#28DCB2',
//     value: '& 3M',
//     title: 'Job posted everyday`s with  qualification',
//   },
// ];

const Features = () => {
  return (
    <div className="how-it-works" id="how-it-works">
      <div
        className="page-width"
        data-section-id={1593429036132}
        style={{ maxWidth: '70%', margin: '0 auto' }}
      >
        <div className="title-row" style={{ textAlign: 'center' }}>
          <h3
            className="subtitle"
            style={{
              fontSize: 13,
              textTransform: 'uppercase',
              color: '#00DB2E',
            }}
          >
            How It Works
          </h3>
          <h2 className="section-title hr-line line-left width-215" style={{}}>
            Powerful Simplicity
          </h2>
        </div>
        <div className="how-it-works-steps desktop-content">
          <div className="hw_step">
            <div className="hw_bubble">
              <span>1</span>
            </div>
            <div className="left_content">
              <div className="content_section">
                <h3>Simple on the surface, intelligent underneath.</h3>
                <p>
                  Our proprietary algorithm bot is connected to private live
                  data to provide the most accurate buy &amp; sell points
                  possible. Also included, MoonAlgo provides other tools like
                  auto trendlines, volatility indicators, automatic support
                  &amp; resistance lines, exit alerts and more.
                </p>
                <LearnMore path="/#pricing" label="Get Access Now" />
              </div>
            </div>
            <div className="right_content">
              <div className="step_image">
                <Image src={smChart} alt="MoonAlgo Small Chart" />
              </div>
            </div>
          </div>
          <div className="hw_step">
            <div className="hw_bubble">
              <span>2</span>
            </div>
            <div className="left_content">
              <div className="step_image">
                <Image src={tradingSig} alt="MoonAlgo Trading Signals" />
              </div>
            </div>
            <div className="right_content">
              <h3>Any trade, any time.</h3>
              <p>
                MoonAlgo works on any market, anytime. That includes stocks,
                crypto, currency, futures, options and more. Signals happen in
                real time, once a candle closes, and MoonAlgo never repaints
                signals.
              </p>
              <LearnMore path="/#pricing" label="Subscribe Now" />
            </div>
          </div>
          <div className="hw_step">
            <div className="hw_bubble">
              <span>3</span>
            </div>
            <div className="left_content">
              <h3>Never miss out on a trade again</h3>
              <p>
                Get access to automated real time buy &amp; sell alerts for
                stocks, crypto, and forex within <a target="_blank" href="https://discord.gg/S5c7VUAkes">Discord</a>. Additionally, as a
                MoonAlgo Insider you can setup custom alerts on any ticker, and
                any timeframe that come straight to your phone via push
                notification, SMS, or email.
              </p>
              <LearnMore path="/#pricing" label="Get Access Now" />
            </div>
            <div className="right_content">
              <div className="step_image">
                <Image src={buyBtn} alt="MoonAlgo Buy Button" />
              </div>
            </div>
          </div>
        </div>
        <div className="hide-mob" style={{ textAlign: 'center' }}>
          <a
            className="btn btn-lg cta-1 mt-1 mb-1"
            data-pageurl="/"
            data-section="How it Works"
            href="/#pricing"
          >
            Subscribe Now
          </a>
        </div>
        <div className="mobile-content how-it-works-steps">
          <div className="hw_step">
            <div className="left_content">
              <div className="step_image">
                <Image src={smChart} alt="MoonAlgo Small Chart" />
              </div>
            </div>
            <div className="right_content">
              <div className="content_section">
                <h3>Simple on the surface, intelligent underneath.</h3>
                <p>
                  Our proprietary algorithm bot is connected to private live
                  data to provide the most accurate buy &amp; sell points
                  possible. Also included, MoonAlgo provides other tools like
                  auto trendlines, volatility indicators, automatic support
                  &amp; resistance lines, exit alerts and more.
                </p>
                <LearnMore path="/#pricing" label="Get Access Now" />
              </div>
            </div>
          </div>
          <div className="hw_step">
            <div className="left_content">
              <div className="step_image">
                <Image src={tradingSig} alt="MoonAlgo Trading Signals" />
              </div>
            </div>
            <div className="right_content">
              <div className="content_section">
                <h3>Any trade, any time.</h3>
                <p>
                  MoonAlgo works on any market, anytime. That includes stocks,
                  crypto, currency, futures, options and more. Signals happen in
                  real time, once a candle closes, and MoonAlgo never repaints
                  signals.
                </p>

                <LearnMore path="/#pricing" label="Subscribe Now" />
              </div>
            </div>
          </div>
          <div className="hw_step">
            <div className="left_content">
              <div className="step_image">
                <Image src={buyBtn} alt="MoonAlgo Buy Button" />
              </div>
            </div>
            <div className="right_content">
              <div className="content_section">
                <h3>Never miss out on a trade again</h3>
                <p>
                  Get access to automated real time buy &amp; sell alerts for
                  stocks, crypto, and forex within <a target="_blank" href="https://discord.gg/S5c7VUAkes">Discord</a>. Additionally, as a
                  MoonAlgo Insider you can setup custom alerts on any ticker,
                  and any timeframe that come straight to your phone via push
                  notification, SMS, or email.
                </p>

                <LearnMore path="/#pricing" label="Subscribe Now" />
              </div>
            </div>
          </div>
        </div>
        <div className="show-mob" style={{ textAlign: 'center' }}>
          <a
            className="btn btn-lg cta-1 mt-1 mb-1"
            data-pageurl="/"
            data-section="How it Works"
            href="/#pricing"
          >
            Subscribe Now
          </a>
        </div>
      </div>
    </div>
    // <Box as="section" variant="section.features">
    //   <Container>
    //     <Box sx={styles.contentWrapper}>
    //       <Box sx={styles.leftContent}>
    //         {data?.map((item) => (
    //           <Feature key={item?.id} feature={item} />
    //         ))}
    //       </Box>
    //       <Box sx={styles.rightContent}>
    //         <SectionHeading
    //           sx={styles.heading}
    //           title={
    //             <Fragment>
    //               Smart Jackpots <br />
    //               that you may love this anytime &amp; anywhere
    //             </Fragment>
    //           }
    //           description="Get your tests delivered at let home collect sample from the victory of the managements that supplies best design system guidelines ever."
    //         />
    //         <Text sx={styles.listItem} as="p">
    //           <Img src={checkFilledCircle} alt="check icon" />
    //           Unlimited design possibility
    //         </Text>
    //         <Text sx={styles.listItem} as="p">
    //           <Img src={checkFilledCircle} alt="check icon" />
    //           Completely responsive features
    //         </Text>
    //         <Box sx={styles.explore}>
    //           <LearnMore path="#!" label="Explore More" />
    //         </Box>
    //       </Box>
    //     </Box>
    //   </Container>
    // </Box>
  );
};

export default Features;

// const styles = {
//   contentWrapper: {
//     gap: [30, 30, 30, 30, 40, 60, 70, 120],
//     display: ['flex', 'flex', 'grid'],
//     flexDirection: ['column-reverse', 'column-reverse', 'unset'],
//     gridTemplateColumns: [
//       '1.2fr 0.8fr',
//       '1.2fr 0.8fr',
//       '1.2fr 0.8fr',
//       '1.1fr 0.9fr',
//       '1.1fr 0.9fr',
//       '1.1fr 0.9fr',
//       '1.2fr 0.8fr',
//     ],
//     alignItems: 'center',
//   },
//   leftContent: {
//     gap: [20, 20, 20, 20, 30, 45],
//     display: ['grid', 'grid'],
//     gridTemplateColumns: 'repeat(2, 1fr)',
//     alignItems: 'flex-start',
//     '> div': {
//       ':first-of-type': {
//         mt: ['65px'],
//       },
//       ':last-of-type': {
//         mt: ['-65px'],
//       },
//     },
//   },
//   rightContent: {
//     // ml: ['120px'],
//   },
//   heading: {
//     textAlign: 'left',
//     mb: ['20px'],
//     mt: [0, 0, '-70px'],
//     h2: {
//       fontSize: ['28px', '28px', '28px', '28px', '36px', '40px'],
//       lineHeight: [1.25, 1.5],
//       letterSpacing: '-1.5px',
//       br: {
//         display: ['none', 'none', 'none', 'block'],
//       },
//     },
//     p: {
//       mt: ['15px', '10px'],
//     },
//   },
//   listItem: {
//     fontWeight: 500,
//     fontSize: 16,
//     lineHeight: 2.81,
//     display: 'flex',
//     alignItems: 'center',
//     img: {
//       mr: '10px',
//     },
//   },
//   explore: {
//     mt: ['20px', '20px', '20px', '20px', '40px'],
//   },
// };
