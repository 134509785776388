const citiesData = require('./cities.json'); // Assuming you have a JSON file with US cities data
const namesData = require('./names.json'); // Assuming you have a JSON file with a list of names

const generateRandomNotificationData = () => {
  const notificationData = [];

  for (let i = 0; i < 1000; i++) {
    const name = getRandomName();
    const location = getRandomLocation();
    const product = getRandomProduct();

    notificationData.push({
      name,
      location,
      product,
    });
  }

  return notificationData;
};

const getRandomName = () => {
  const randomIndex = Math.floor(Math.random() * namesData.length);
  return namesData[randomIndex];
};

const getRandomLocation = () => {
  const randomIndex = Math.floor(Math.random() * citiesData.length);
  const city = citiesData[randomIndex].city;
  const state = citiesData[randomIndex].state;
  return `${city}, ${state}`;
};

const getRandomProduct = () => {
  const randomNum = Math.random();
  return randomNum < 0.7 ? 'MoonAlgo Gold' : 'MoonAlgo Silver';
};

export default generateRandomNotificationData();
