/** @jsx jsx */
import { jsx, Container, Box } from 'theme-ui';
import SectionHeader from 'components/section-heading';
import Accordion from 'components/accordion/accordion';
const faqs = [
  {
    title: "What is MoonAlgo's Automated Trading Algorithm, and what it does?",
    contents: (
      <div>
        MoonAlgo's Automated Trading Algorithm is designed to make trading
        simpler, more efficient, and more profitable. Our cutting-edge
        technology allows traders to automate their trades based on market
        trends and signals, eliminating the need for constant monitoring and
        decision making.
        <br />
        Our algorithm uses advanced mathematical models and machine learning
        techniques to analyze market data and generate signals in real-time.
        This approach ensures that traders are provided with the most accurate
        and reliable signals in the market.
        <br />
        Our Automated Trading Algorithm is suitable for any market and any
        timeframe, making it the perfect solution for both short-term and
        long-term traders. It can be used to trade equities, indices, currency,
        futures, and commodities.
        <br />
        By using our Automated Trading Algorithm, traders can save time, reduce
        stress, and increase their chances of making profitable trades. Sign up
        today and start seeing the benefits of using MoonAlgo's Automated
        Trading Algorithm.
      </div>
    ),
  },
  {
    title: 'What markets & timeframes does this work with?',
    contents: (
      <div>
        MoonAlgo's trading tools support all international markets including
        equities, indices, currencies, futures, and commodities. With its
        ability to trade on multiple timeframes, ranging from 1 minute to 1
        week, it caters to all types of traders, whether you're a scalper, swing
        trader, or options trader.
        <br />
        Our tools are designed to provide you with the flexibility to adapt to
        changing market conditions and to suit your trading style. With
        MoonAlgo, you can be sure that you have the right tool for the job, no
        matter what the market conditions are. So, whether you're looking to
        take advantage of short-term fluctuations or long-term trends, MoonAlgo
        has you covered.
      </div>
    ),
  },
  {
    title: 'Does it repaint?',
    contents: (
      <div>
        MoonAlgo's indicators are designed to provide traders with accurate and
        reliable signals that are not repainted on the chart. Our cutting-edge
        technology ensures that the signals are generated in real-time and
        remain unchanged, providing traders with a clear and concise view of the
        market.
        <br />
        When it comes to trading, the last thing you want is to be misled by
        indicators that repaint on the chart. Repainting indicators can cause
        confusion and indecision, leading to missed opportunities and potential
        losses. With MoonAlgo, you can be sure that you are getting the most
        accurate and reliable signals in the market.
      </div>
    ),
  },
  {
    title: `When is a signal confirmed?`,
    contents: (
      <div>
        Signals are only considered confirmed once the candle has closed. This
        means that the signal is only confirmed once the previous candle has
        closed and the next candle has started. This approach ensures that
        traders are provided with the most accurate and reliable signals in the
        market.
      </div>
    ),
  },
];
export default function Faq() {
  return (
    <Box as="section" id="faq" variant="section.faq">
      <Container>
        <SectionHeader
          title="Do you have any question"
          description="Our support team ready to help you, please contact with them"
        />
        <Box
          sx={{
            display: 'flex',
            width: ['100%', null, null, '650px', '745px'],
            flexDirection: 'column',
            mx: 'auto',
            my: -4,
          }}
        >
          <Accordion items={faqs} />
        </Box>
      </Container>
    </Box>
  );
}
