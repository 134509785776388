/** @jsx jsx */
import { jsx, Image, Container, Box } from 'theme-ui';

import SectionHeader from 'components/section-heading';
import tick from 'assets/images/tick.svg';
import testimonial1 from 'assets/images/testimonial-1.jpg';
import testimonial2 from 'assets/images/testimonial-2.jpg';
import testimonial3 from 'assets/images/testimonial-3.jpg';

const Reviews = () => {
  return (
    <Box as="section" id="reviews" variant="section.faq">
      <Container>
        <SectionHeader
          title="Reviews"
          description="How our traders feel about the complete trading system"
        />
        <div className="reviews-boxes">
          <div className="reviews-box">
            <div className="reviews-img-div">
              <Image
                src={testimonial1}
                className="reviews-img"
                alt="MoonAlgo's Testimonial"
              />
            </div>
            <div className="testimonial-content">
              <div className="testimonial-name">
                <p>Madhur Ramanathan</p>
                <Image src={tick} alt="tick" />
              </div>
              <div>
                <div className="testimonial-text">
                  "Discovering MoonAlgo was a game-changer for me in refining my
                  trading strategy. Its evolving system cuts through the
                  clutter, allowing me to elevate my win rate with logical
                  insights."
                </div>
              </div>
            </div>
          </div>
          <div className="reviews-box">
            <div className="reviews-img-div">
              <Image
                src={testimonial2}
                className="reviews-img"
                alt="MoonAlgo's Testimonial"
              />
            </div>
            <div className="testimonial-content">
              <div className="testimonial-name">
                <p>Jake Michael</p>
                <Image src={tick} alt="tick" />
              </div>
              <div>
                <div className="testimonial-text">
                  "MoonAlgo has transformed my trading journey. Its dynamic
                  system simplifies complexities and guides me towards winning
                  trades. With MoonAlgo, I've gained a competitive edge that has
                  boosted my success in the market."
                </div>
              </div>
            </div>
          </div>
          <div className="reviews-box">
            <div className="reviews-img-div">
              <Image
                src={testimonial3}
                className="reviews-img"
                alt="MoonAlgo's Testimonial"
              />
            </div>
            <div className="testimonial-content">
              <div className="testimonial-name">
                <p>John James</p>
                <Image src={tick} alt="tick" />
              </div>
              <div>
                <div className="testimonial-text">
                  "MoonAlgo's evolving technology has streamlined my trading
                  approach, providing accurate signals and insights that have
                  significantly improved my win rate. MoonAlgo is the secret
                  weapon every trader needs."
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default Reviews;
